









































































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OCompanyData: () =>
      import("@/components/organisms/event/company/o-company-data.vue"),
    OCompanyRepresentatives: () =>
      import(
        "@/components/organisms/event/company/o-company-representatives.vue"
      ),
    OCompanyStand: () =>
      import("@/components/organisms/event/company/o-company-stand.vue"),
    OCompanyServices: () =>
      import("@/components/organisms/event/company/o-company-services.vue"),
    OCompanySummary: () =>
      import("@/components/organisms/event/company/o-company-summary.vue"),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
      representatives: any;
      stand: any;
      services: any;
    }>({
      stepper: 1,
      data: {
        isActive: false,
        package: "none",
        languageVersion: "polish",
      },
      representatives: [],
      stand: {
        buildingIsRequired: false,
      },
      services: {
        totalServicePrice: 0,
        additives: [],
      },
    });

    const dataSummary = computed(() => ({
      data: model.data,
      representatives: model.representatives,
      stand: model.stand,
      services: model.services,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (!model.data.name)
          errors.push({ step: 1, error: "Podaj nazwę firmy." });
        if (!model.data.representativeEmail)
          errors.push({ step: 1, error: "Podaj e-mail przedstawiciela." });

        return errors;
      }),
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isStepEditable = (step: number) => {
      // return model.stepper > step
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isActive: model.data.isActive || false,
        name: model.data.name,
        city: model.data.city || undefined,
        street: model.data.street || undefined,
        postalCode: model.data.postalCode || undefined,
        nip: model.data.nip || undefined,
        phoneNumber: model.data.phoneNumber || undefined,
        note: model.data.note || undefined,
        representativeFirstName:
          model.data.representativeFirstName || undefined,
        representativeLastName: model.data.representativeLastName || undefined,
        representativePhoneNumber:
          model.data.representativePhoneNumber || undefined,
        representativeEmail: model.data.representativeEmail,
        invoiceNumber: model.data.invoiceNumber || undefined,
        dateOfInvoice: model.data.dateOfInvoice || undefined,
        invoicePaymentDate: model.data.invoicePaymentDate || undefined,
        package: model.data.package || "none",
        representatives: model.representatives || undefined,
        standWidth: model.stand.standWidth || undefined,
        standDepth: model.stand.standDepth || undefined,
        numberOfTables: model.stand.numberOfTables || undefined,
        numberOfChairs: model.stand.numberOfChairs || undefined,
        accessToElectricity: model.stand.accessToElectricity || false,
        power: model.stand.power || undefined,
        attentions: model.stand.attentions || undefined,
        buildingIsRequired: model.stand.buildingIsRequired || undefined,
        buildingCompany: model.stand.buildingCompany || undefined,
        buildingPhoneNumber: model.stand.buildingPhoneNumber || undefined,
        buildingEmail: model.stand.buildingEmail || undefined,
        additives: model.services.additives || undefined,
        totalServicePrice: model.services.totalServicePrice || undefined,
        languageVersion: model.data.languageVersion || undefined,
      };

      state.loading = true;

      axiosInstance
        .post(`/event/${root.$route.params.id}/company`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          (model.stepper = 1),
            (model.data = {
              isActive: false,
              package: "none",
            }),
            (model.representatives = []),
            (model.stand = {
              buildingIsRequired: false,
            }),
            (model.services = []);
        })
        .catch((error) => {
          state.error = error.response.status;
          state.loading = false;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Firma już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage,
    };
  },
});
